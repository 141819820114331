import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import BoltIcon from "@mui/icons-material/Bolt";
import { Card, CardActions, CardContent, Chip, Stack } from "@mui/material";
import { type Agent } from "@src/appV2/Agents";
import { CardAlert } from "@src/appV2/lib/Alert";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { FacilityDetails } from "@src/appV2/Shifts/MyShifts/components/ShiftCard/FacilityDetails";
import { StreakAlertWrapper } from "@src/appV2/Streaks/components/StreakAlertWrapper";
import { formatDistanceToNow, isFuture, parseISO } from "date-fns";
import { useRouteMatch } from "react-router-dom";

import { ShiftDurationWithLunchBreakIcon } from "../MyShifts/components/ShiftCard/ShiftDurationWithLunchBreakIcon";
import { ShiftTimezone } from "../MyShifts/components/ShiftCard/ShiftTimezone";
import { formatRate } from "../MyShifts/utils";
import { DEFAULT_TIMEZONE } from "../Shift/constants";
import { useShiftInviteItemContext } from "./context/shiftInviteItemContext";
import { ShiftInviteCardAction } from "./ShiftInviteCardAction";
import type { ShiftInvite, ShiftInviteStatus } from "./types";

export interface ShiftInviteCardProps {
  shiftInvite: ShiftInvite;
  onAcceptShiftInvite: () => void;
  onDeclineShiftInvite: () => void;
  isLoading?: boolean;
  shiftInviteAction?: ShiftInviteStatus;
  agent: Agent;
}

export function ShiftInviteCard(props: ShiftInviteCardProps) {
  const {
    shiftInvite,
    onAcceptShiftInvite,
    onDeclineShiftInvite,
    isLoading,
    shiftInviteAction,
    agent,
  } = props;
  const {
    shiftDetails,
    expiresAt: shiftInviteExpiresAt,
    facility,
    workerId,
    workplaceId,
    shiftId,
  } = shiftInvite.attributes;
  const { chatChannelsData } = useShiftInviteItemContext();

  const timeZone = shiftDetails.tmz ?? DEFAULT_TIMEZONE;
  const shiftExpiresAtDate = parseISO(shiftInviteExpiresAt);
  const { path: currentRouterUrl } = useRouteMatch();

  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          paddingX: 3,
          paddingY: 2,
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text variant="h3">
                  {formatShortDateWithWeekday(shiftDetails.start, { timeZone })}
                </Text>
                <CardAlert
                  label={
                    isFuture(shiftExpiresAtDate)
                      ? `Expires in ${formatDistanceToNow(shiftExpiresAtDate, {
                          includeSeconds: true,
                        })}`
                      : "Expired"
                  }
                />
              </Stack>
              <ShiftDurationWithLunchBreakIcon
                shift={{ start: shiftDetails.start, end: shiftDetails.end, facility }}
                timeZone={timeZone}
              />
              <ShiftTimezone timeZone={timeZone} viewer={agent} />
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Text
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.success.main, fontWeight: 600 }}
                >
                  {`${formatRate(shiftDetails.pay)} / hr`}
                </Text>
                <Stack direction="row" spacing={0.25}>
                  <BoltIcon sx={{ color: (theme) => theme.palette.success.main }} />
                  <Text
                    variant="body2"
                    sx={{ color: (theme) => theme.palette.success.main, fontWeight: 600 }}
                    align="center"
                  >
                    InstantPay
                  </Text>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row">
              <Chip size="small" label={shiftDetails.agentReq} />
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <FacilityDetails
              agent={agent}
              facility={facility}
              chatChannels={chatChannelsData ?? []}
              onClickChatButton={() => {
                logEvent(APP_V2_USER_EVENTS.SHIFT_CARD_CHAT_BUTTON_CLICKED, {
                  shiftInviteId: shiftInvite.id,
                  shiftId,
                  agentId: agent._id,
                  facilityId: facility.userId,
                  location: currentRouterUrl,
                });
              }}
            />
          </Stack>
          <StreakAlertWrapper
            isOpenShift
            shiftStartTime={parseISO(shiftDetails.start)}
            shiftEndTime={parseISO(shiftDetails.end)}
            workerId={workerId}
            workplaceId={workplaceId}
            onlyCheckActiveStreak={false}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <ShiftInviteCardAction
          isLoading={isLoading}
          shiftInviteAction={shiftInviteAction}
          onAcceptShiftInvite={onAcceptShiftInvite}
          onDeclineShiftInvite={onDeclineShiftInvite}
        />
      </CardActions>
    </Card>
  );
}
