import { type Agent } from "@src/appV2/Agents";
import { type ShiftInvite } from "@src/appV2/Shifts/ShiftInvites/types";

import { useShiftInviteActions } from "./api/useShiftInviteActions";
import { ShiftInviteCard } from "./ShiftInviteCard";
import { ShiftInviteItemDialog } from "./ShiftInviteItemDialog";

interface ShiftInviteItemProps {
  shiftInvite: ShiftInvite;
  agent: Agent;
}
export function ShiftInviteItem(props: ShiftInviteItemProps) {
  const { shiftInvite, agent } = props;
  const { id: shiftInviteId } = shiftInvite;
  const {
    acceptShiftInvite,
    declineShiftInvite,
    isLoadingAcceptOrDecline,
    acceptOrDeclineRequest,
  } = useShiftInviteActions();

  return (
    <>
      <ShiftInviteCard
        shiftInvite={shiftInvite}
        isLoading={isLoadingAcceptOrDecline}
        shiftInviteAction={acceptOrDeclineRequest?.status}
        agent={agent}
        onAcceptShiftInvite={async () => {
          await acceptShiftInvite({ shiftInvite });
        }}
        onDeclineShiftInvite={async () => {
          await declineShiftInvite({ shiftInviteId });
        }}
      />
      <ShiftInviteItemDialog shiftInvite={shiftInvite} />
    </>
  );
}
