import { IonPage } from "@ionic/react";
import { Alert } from "@mui/material";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";

import { AppBarHeader, BackButtonLink, PageWithHeader } from "../../lib";
import { useAgentPendingShiftInvites } from "./api/useAgentPendingShiftInvites";
import { EmptyShiftInvites } from "./EmptyShiftInvites";
import { ShiftInviteCardSkeleton } from "./ShiftInviteCardSkeleton";
import { ShiftInvites } from "./ShiftInvites";
import { type ShiftInvite } from "./types";

export function ShiftInvitesPage() {
  const {
    data: shiftInvitesData,
    isLoading: isPendingShiftInvitesLoading,
    isSuccess: isPendingShiftInvitesSuccess,
    isError: isPendingShiftInviteError,
    refetch: refetchShiftInvites,
  } = useAgentPendingShiftInvites();
  const { data: agent, isSuccess: isAgentProfileSuccess } = useAgentProfile();
  const shiftInvites: ShiftInvite[] = shiftInvitesData?.data ?? [];

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Shift Invites"
            leftCta={
              <BackButtonLink
                alwaysUseDefaultBackTo
                defaultBackTo={DeprecatedGlobalAppV1Paths.MY_SHIFTS}
              />
            }
          />
        }
        onRefresh={refetchShiftInvites}
      >
        {isPendingShiftInvitesLoading && <ShiftInviteCardSkeleton />}
        {isPendingShiftInviteError && <Alert severity="error">Error loading shift invites.</Alert>}
        {isAgentProfileSuccess &&
          isPendingShiftInvitesSuccess &&
          (shiftInvites.length > 0 ? (
            <ShiftInvites shiftInvites={shiftInvites} agent={agent} />
          ) : (
            <EmptyShiftInvites />
          ))}
      </PageWithHeader>
    </IonPage>
  );
}
