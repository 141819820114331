import { Stack } from "@mui/material";
import { type Agent } from "@src/appV2/Agents";
import { ShiftInviteItem } from "@src/appV2/Shifts/ShiftInvites/ShiftInviteItem";

import { ShiftInviteItemProvider } from "./context/shiftInviteItemContext";
import { type ShiftInvite } from "./types";

interface ShiftInvitesProps {
  shiftInvites: ShiftInvite[];
  agent: Agent;
}

export function ShiftInvites(props: ShiftInvitesProps) {
  const { shiftInvites, agent } = props;

  return (
    <Stack spacing={2}>
      {shiftInvites.map((shiftInvite) => (
        <ShiftInviteItemProvider key={shiftInvite.id}>
          <ShiftInviteItem shiftInvite={shiftInvite} agent={agent} />
        </ShiftInviteItemProvider>
      ))}
    </Stack>
  );
}
