import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, DialogActions, DialogContent, Stack } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import {
  CloseIconType,
  FullScreenDialogTitle,
} from "@src/appV2/lib/Dialogs/FullScreen/DialogTitle";
import { type ReactNode } from "react";

interface PolicyDialogProps {
  modalState: UseModalState;
  dialogTitle: string;
  content: ReactNode;
  contentIcon?: ReactNode;
  successButtonText?: string;
  onSuccess: () => void;
  onClose: () => void;
}

export function PolicyDialog(props: PolicyDialogProps) {
  const { modalState, onClose, onSuccess, dialogTitle, contentIcon, content, successButtonText } =
    props;

  return (
    <FullScreenDialog modalState={modalState}>
      <FullScreenDialogTitle
        closeIconType={CloseIconType.CLOSE}
        onClose={() => {
          modalState.closeModal();
          onClose();
        }}
      >
        {dialogTitle}
      </FullScreenDialogTitle>
      <DialogContent dividers>
        <Stack spacing={4} direction="column" alignItems="center">
          {isDefined(contentIcon) ? <Box pt={4}>{contentIcon}</Box> : null}
          <Box>{content}</Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: "max(env(safe-area-inset-left), 16px)" }}>
        <Button
          fullWidth
          variant="contained"
          type="submit"
          onClick={() => {
            modalState.closeModal();
            onSuccess();
          }}
        >
          {successButtonText ?? "Continue"}
        </Button>
      </DialogActions>
    </FullScreenDialog>
  );
}
